import React, {useEffect, useRef, useState} from 'react';

export default ({file, setDuration, width}) => {
    const [loading, setLoading] = useState(false);
    const [thumb, setThumb] = useState();
    const [mime, setMime] = useState();

    const urlRef = useRef(null)
    const videoRef = useRef();

    useEffect(() => {
        console.log(videoRef)
    }, [videoRef])

    useEffect(() => {

        if (file && !(file instanceof File)) setThumb(file);
        else {
            setLoading(!!file);
            if (file)
                setMime(file.type);
        }

        return () => {
            (window.URL || window.webkitURL).revokeObjectURL(urlRef.current);
        }
    }, [file])

    useEffect(() => {
        if (!loading) return;
        setThumb("");
        const reader = new FileReader();
        reader.onload = (e) => {
            const blob = new Blob([e.target.result], {type: mime}),
                video = videoRef.current;
            urlRef.current = (window.URL || window.webkitURL).createObjectURL(blob);
            video.src = urlRef.current;
        };
        reader.onloadend = () => {
            setLoading(false);
            setThumb(reader.result);
        };
        reader.readAsArrayBuffer(file);
    }, [loading])

    const handleOnLoadedMetaData = () => {
        if (setDuration)
            setDuration(videoRef.current.duration);
    };

    return <video ref={videoRef} width={width ? width : 300} height={width ? "auto" : 300} className="img-thumbnail m-2"
                  controls preload="metadata"
                  onLoadedMetadata={handleOnLoadedMetaData}>
        {thumb && <source src={thumb}/>}
    </video>

}

