import React, {useState} from 'react';

export const cardTypes = [null,
    "SingleReview",     //1
    "MultipleReview",   //2
    "VideoReview",      //3
    "SmallNews",        //4
    "TallNews",         //5
    "GrandeNews",       //6
    "VentiNews",        //7
    "SmallAppBundle",   //8
    "MediumAppBundle",  //9
    "AppList",          //10
    "Survey",           //11
    "Video",            //12
    "Game"              //13
];

const CardType = ({cardType}) => {
    return <span>{cardTypes[cardType]}</span>
}

export default CardType;