import React, {useRef, useState} from 'react';
import {Avatar} from "@material-ui/core";
import {Link} from "react-router-dom";
import {signoutRedirect} from "../services/userService";


const Navbar = props => {
    const [user, setUser] = useState({name: "Maziar"});

    function signOut() {
        signoutRedirect()
    }

    const navRef = useRef(null);

    const handleClose = () => {
        console.log("hi")
        if (navRef.current) {
            navRef.current.classList.remove('show')
        }
    }
    return <>
        <div className="navbar navbar-expand-md navbar-dark bg-indigo">
            <div className="navbar-brand wmin-0 mr-5">
                <Link to="/" className="d-inline-block">
                    <img src="/assets/images/logo_light.png" alt=""/>
                </Link>
            </div>

            {/*<div className="d-md-none">*/}
            {/*    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">*/}
            {/*        <i className="icon-tree5"></i>*/}
            {/*    </button>*/}
            {/*</div>*/}

            {/*<div className="collapse navbar-collapse" id="navbar-mobile">*/}
            {/*    /!*<ul className="navbar-nav ml-md-auto">*!/*/}
            {/*    /!*    <li className="nav-item dropdown dropdown-user">*!/*/}
            {/*    /!*        <a href="#" className="navbar-nav-link d-flex align-items-center dropdown-toggle"*!/*/}
            {/*    /!*           data-toggle="dropdown">*!/*/}
            {/*    /!*            <img src="/assets/images/placeholders/placeholder.jpg"*!/*/}
            {/*    /!*                 className="rounded-circle mr-2" height="34" alt=""/>*!/*/}
            {/*    /!*            <span>Victoria</span>*!/*/}
            {/*    /!*        </a>*!/*/}
            {/*    */}
            {/*    /!*        <div className="dropdown-menu dropdown-menu-right">*!/*/}
            {/*    /!*            <a href="#" className="dropdown-item"><i className="icon-user-plus"></i> My profile</a>*!/*/}
            {/*    /!*            <div className="dropdown-divider"></div>*!/*/}
            {/*    /!*            <a href="#" className="dropdown-item"><i className="icon-cog5"></i> Account settings</a>*!/*/}
            {/*    /!*            <a href="#" className="dropdown-item"><i className="icon-switch2"></i> Logout</a>*!/*/}
            {/*    /!*        </div>*!/*/}
            {/*    /!*    </li>*!/*/}
            {/*    /!*</ul>*!/*/}
            {/*</div>*/}
            <ul className="navbar-nav ml-auto">


                <li className="nav-item">
                    <a className="navbar-nav-link italian" onClick={() => signOut()}>
                        <i className="icon-switch"></i>
                    </a>
                </li>
            </ul>
        </div>
        <div className="navbar navbar-expand-md navbar-light">
            <div className="text-center d-md-none w-100">
                <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse"
                        data-target="#navbar-navigation">
                    <i className="icon-unfold mr-2"></i>
                    Navigation
                </button>
            </div>

            <div className="navbar-collapse collapse" id="navbar-navigation">
                <ul className="navbar-nav navbar-nav-highlight">
                    {/*<li className="nav-item">*/}
                    {/*    <a href="index.html" className="navbar-nav-link active">*/}
                    {/*        <i className="icon-home4 mr-2"></i>*/}
                    {/*        Dashboard*/}
                    {/*    </a>*/}
                    {/*</li>*/}

                    <li className="nav-item nav-item-levels mega-menu-full">
                        <a href="#" className="navbar-nav-link dropdown-toggle" data-toggle="dropdown">
                            <i className="icon-make-group mr-2"></i>
                            Navigation
                        </a>

                        <div className="dropdown-menu dropdown-content" ref={navRef}>
                            <div className="dropdown-content-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div
                                            className="font-size-sm line-height-sm font-weight-semibold text-uppercase mt-1">Card
                                        </div>
                                        <div className="dropdown-divider mb-2"/>
                                        <div className="dropdown-item-group mb-3 mb-md-0">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <Link to={"/App"} onClick={handleClose}
                                                          className="dropdown-item rounded">
                                                        <i className="icon-width"/>
                                                        App
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/Card"}
                                                          onClick={handleClose}
                                                          className="dropdown-item rounded">
                                                        <i className="icon-copy"/> Card
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/PushNotification"}
                                                          onClick={handleClose}
                                                          className="dropdown-item rounded">
                                                        <i className="icon-color-sampler"/> Push Notification</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div
                                            className="font-size-sm line-height-sm font-weight-semibold text-uppercase mt-1">App
                                        </div>
                                        <div className="dropdown-divider mb-2"></div>
                                        <div className="dropdown-item-group mb-3 mb-md-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    {/*<li className="nav-item dropdown">*/}
                    {/*    <a href="#" className="navbar-nav-link dropdown-toggle" data-toggle="dropdown">*/}
                    {/*        <i className="icon-strategy mr-2"></i>*/}
                    {/*        Starter kit*/}
                    {/*    </a>*/}

                    {/*    <div className="dropdown-menu">*/}
                    {/*        <div className="dropdown-header">Basic layouts</div>*/}
                    {/*        <div className="dropdown-submenu">*/}
                    {/*            <a href="#" className="dropdown-item dropdown-toggle"><i*/}
                    {/*                className="icon-grid2"></i> Sidebars</a>*/}
                    {/*            <div className="dropdown-menu">*/}
                    {/*                <a href="../seed/sidebar_none.html" className="dropdown-item">No sidebar</a>*/}
                    {/*                <a href="../seed/sidebar_main.html" className="dropdown-item">1 sidebar</a>*/}
                    {/*                <div className="dropdown-submenu">*/}
                    {/*                    <a href="#" className="dropdown-item dropdown-toggle">2 sidebars</a>*/}
                    {/*                    <div className="dropdown-menu">*/}
                    {/*                        <a href="../seed/sidebar_secondary.html" className="dropdown-item">Secondary*/}
                    {/*                            sidebar</a>*/}
                    {/*                        <a href="../seed/sidebar_right.html" className="dropdown-item">Right*/}
                    {/*                            sidebar</a>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="dropdown-submenu">*/}
                    {/*                    <a href="#" className="dropdown-item dropdown-toggle">3 sidebars</a>*/}
                    {/*                    <div className="dropdown-menu">*/}
                    {/*                        <a href="../seed/sidebar_right_hidden.html" className="dropdown-item">Right*/}
                    {/*                            sidebar hidden</a>*/}
                    {/*                        <a href="../seed/sidebar_right_visible.html" className="dropdown-item">Right*/}
                    {/*                            sidebar visible</a>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <a href="../seed/sidebar_sections.html" className="dropdown-item">Sectioned*/}
                    {/*                    sidebar</a>*/}
                    {/*                <a href="../seed/sidebar_stretched.html" className="dropdown-item">Stretched*/}
                    {/*                    sidebar</a>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="dropdown-submenu">*/}
                    {/*            <a href="#" className="dropdown-item dropdown-toggle"><i*/}
                    {/*                className="icon-paragraph-justify3"></i> Navbars</a>*/}
                    {/*            <div className="dropdown-menu">*/}
                    {/*                <a href="../seed/navbar_main_fixed.html" className="dropdown-item">Main navbar*/}
                    {/*                    fixed</a>*/}
                    {/*                <a href="../seed/navbar_main_hideable.html" className="dropdown-item">Main navbar*/}
                    {/*                    hideable</a>*/}
                    {/*                <a href="../seed/navbar_secondary_sticky.html" className="dropdown-item">Secondary*/}
                    {/*                    navbar sticky</a>*/}
                    {/*                <a href="../seed/navbar_both_fixed.html" className="dropdown-item">Both navbars*/}
                    {/*                    fixed</a>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="dropdown-header">Optional layouts</div>*/}
                    {/*        <a href="../seed/layout_boxed.html" className="dropdown-item"><i*/}
                    {/*            className="icon-align-center-horizontal"></i> Boxed layout</a>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                </ul>

                {/*<ul className="navbar-nav navbar-nav-highlight ml-md-auto">*/}

                {/*    <li className="nav-item dropdown">*/}
                {/*        <a href="#" className="navbar-nav-link dropdown-toggle" data-toggle="dropdown">*/}
                {/*            <i className="icon-cog3"></i>*/}
                {/*            <span className="d-md-none ml-2">Settings</span>*/}
                {/*        </a>*/}

                {/*        <div className="dropdown-menu dropdown-menu-right">*/}
                {/*            <a href="#" className="dropdown-item"><i className="icon-user-lock"></i> Account*/}
                {/*                security</a>*/}
                {/*            <a href="#" className="dropdown-item"><i className="icon-statistics"></i> Analytics</a>*/}
                {/*            <a href="#" className="dropdown-item"><i*/}
                {/*                className="icon-accessibility"></i> Accessibility</a>*/}
                {/*            <div className="dropdown-divider"></div>*/}
                {/*            <a href="#" className="dropdown-item"><i className="icon-gear"></i> All settings</a>*/}
                {/*        </div>*/}
                {/*    </li>*/}
                {/*</ul>*/}
            </div>
        </div>
    </>
};

export default Navbar;