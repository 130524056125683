import Select, {components} from 'react-select';
import {
    SortableContainer,
    SortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import {useEffect} from "react";

function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;


}

const SortableMultiValue = SortableElement(props => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const innerProps = {...props.innerProps, onMouseDown};
    return <components.MultiValue {...props} innerProps={innerProps}/>;
});

const SortableMultiValueLabel = sortableHandle(props => (
    <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select);

export default ({options, isLoading, field, form, isMulti = true}) => {
    return (<SortableSelect
            useDragHandle
            axis="xy"
            onSortEnd={({oldIndex, newIndex}) => {
                form.setFieldValue(field.name, arrayMove(field.value, oldIndex, newIndex))
            }}
            distance={4}
            getHelperDimensions={({node}) => node.getBoundingClientRect()}
            components={{
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
            }}
            isLoading={isLoading}
            isMulti={isMulti} isSearchable
            options={options}
            value={options && field.value ? field.value.map(({id}) => (options.find(x => x.value === id))) : []}
            onChange={(option) => form.setFieldValue(field.name, option.map(o => ({id: o.value})))}
            onBlur={form.onBlur}
            name={field.name}
        />
    )
}