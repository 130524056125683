import {createSlice, createAction} from "@reduxjs/toolkit";

export const cardListSliceName = 'cardList';

export const cardListSlice = createSlice({
    name: cardListSliceName,
    initialState: {
        data: {},
        isLoading: false,
        isPopUpVisible: false,
        isSubmitting: false,
        refreshGridView: false
    },
    reducers: {
        setCard: (state, {payload}) => {
            state.data = payload;
        },
        clearCard: state => {
            state.data = {};
        },
        setIsSubmitting: (state, {payload}) => {
            state.isSubmitting = payload;
        },
        setRefreshGridView: (state, {payload}) => {
            state.refreshGridView = payload
        }
    },
});

export const fetchCardList = createAction(`${cardListSliceName}/fetchCardList`)

export const {setCard, clearCard, setIsSubmitting, setRefreshGridView} = cardListSlice.actions;
export default cardListSlice.reducer;