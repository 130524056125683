import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import cardListReducer from "./slices/cardListSlice";
import appListReducer from "./slices/appListSlice";
import authReducer from "./slices/authSlice";
import {coreMiddlewares} from "./middlewares/core";
import {applicationMiddlewares} from "./middlewares/app";

export default configureStore({
    reducer: {
        cardList: cardListReducer,
        appList: appListReducer,
        auth: authReducer
    },
    middleware: [...getDefaultMiddleware(), ...coreMiddlewares, ...applicationMiddlewares]
})