import React, {useEffect, useState} from 'react';
import CardWrapper from "./CardWrapper";
import {Field} from "formik";
import {createStore} from "devextreme-aspnet-data-nojquery";
import {SelectBox} from "devextreme-react";
import FileUploader from "devextreme-react/file-uploader";
import Thumb from "./imageThumbnail";
import VideoThumb from "./videoThumbnail";
 
import DataSource from "devextreme/data/data_source";
import {Editor} from "@tinymce/tinymce-react";

const baseUrl = `${window.config.ApiBase}/api/v1/CMS`;

const remoteDataSource = new DataSource({
    store: createStore({
        key: 'id',
        loadUrl: baseUrl + '/GetApps',
    }),
    paginate: true, pageSize: 10,
})

export default ({index, onRemove}) => {

    return <CardWrapper title="App" onRemove={onRemove}>
        <Field
            id={`data[${index}].appId`}
            name={`data[${index}].appId`}
        >
            {({field, form, meta: {touched, error}}) => {
                return <SelectBox dataSource={remoteDataSource}
                                  displayExpr="name"
                                  valueExpr="id"
                                  value={field.value}
                                  onValueChanged={e => {
                                      form.setFieldValue(field.name, e.value)
                                  }}
                                  searchEnabled={true}
                                  searchExpr={['name']}
                                  minSearchLength={3}
                                  searchTimeout={300}
                                  showDataBeforeSearch
                />
            }}
        </Field>
        <Field
            id={`data[${index}].content`}
            name={`data[${index}].content`}
        >
            {({field, form, meta: {touched, error}}) => {
                const [localValue, setLocalValue] = useState(field.value);
                const handleFocusOut = () => {
                    form.setFieldValue(field.name, localValue)
                }
                return <Editor apiKey="wagqbgjh92bjdkler5dwhdnim0hhn5st8r3gjakh7buhw59p"
                               value={field.value}
                               onEditorChange={(e) => {
                                   form.handleChange({target: {name: field.name, value: e}})
                               }}
                               init={{
                                   height: 350,
                                   menubar: false,
                                   plugins: [
                                       'advlist autolink lists link anchor',
                                       'searchreplace visualblocks code fullscreen',
                                       'paste wordcount emoticons'
                                   ],
                                   toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
                                       'removeformat | link autolink | emoticons | code fullscreen',
                                   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                   forced_root_block: '',
                                   branding: false
                               }}
                />
            }}
        </Field>
        <Field
            id={`data[${index}].imgUri`}
            name={`data[${index}].imgUri`}
        >
            {({field, form, meta: {touched, error}}) => {
                return <div style={{display: "flex"}}>
                    <FileUploader selectButtonText="Select photo" labelText="" accept="image/*" uploadMode="useForm"
                                  onValueChange={(file) => {
                                      form.setFieldValue(field.name, file[0])
                                  }}/>
                    <Thumb file={field.value}/>
                </div>
            }}
        </Field>
        <Field
            id={`data[${index}].videoUri`}
            name={`data[${index}].videoUri`}
        >
            {({field, form, meta: {touched, error}}) => {
                const [duration, setDuration] = useState();

                useEffect(() => {
                    if (duration && duration > 0)
                        form.setFieldValue(`data[${index}].duration`, Math.round(duration * 1000))
                }, [duration])

                return <div style={{display: "flex"}}>
                    <FileUploader selectButtonText="Select video" labelText="" accept="video/*" uploadMode="useForm"
                                  onValueChange={(file) => {
                                      form.setFieldValue(field.name, file[0])
                                  }}/>
                    <VideoThumb file={field.value} setDuration={setDuration}/>
                </div>
            }}
        </Field>
    </CardWrapper>
};

