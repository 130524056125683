import React from 'react';
import {Chip} from "@material-ui/core";

export const states = [
    "NotPublished",
    "Published"
]

const stateColor = [
    {backgroundColor: "tomato", color: 'whitesmoke'},
    {backgroundColor: 'green', color: 'whitesmoke'}]


function State({value}) {
    return (
        <Chip label={states[value]} size={"small"} style={stateColor[value]}/>
    );
}

export default State;