import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {ValidationSchema} from "./AppUrlSection";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {serialize} from "object-to-formdata";
import {clearApp} from "../../store/slices/appListSlice";
import {Form, Formik} from "formik";
import {Button, Step, StepButton, Stepper} from "@material-ui/core";
import AppUrlSection from "./AppUrlSection";
import AppDetailSection from "./AppDetailSection";
import toast from 'react-hot-toast';

const formSteps = [
    {
        label: "Enter App Share Url",
        enabledInNewApp: true,
        content: ({formik, nextStep}) => <AppUrlSection formik={formik} nextStep={nextStep}/>,
        ValidationSchema,
    },
    {
        label: "Edit App Info",
        enabledInNewApp: true,
        content: ({formik}) => <AppDetailSection formik={formik}/>,
    },
];

const baseUrl = `${window.config.ApiBase}/api/v1/CMS`;

export default ({isNewApp = false}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const formData = useSelector(({appList}) => appList.data);
    const initialStep = isNewApp ? 0 : 1;
    const [activeStep, setActiveStep] = useState(initialStep);
    const [completed, setCompleted] = useState(new Set());

    const handleStep = (step) => {
        if (step > activeStep) {
            const newCompleted = new Set(completed);
            newCompleted.add(activeStep);
            setCompleted(newCompleted);
        } else {
            const newCompleted = [...completed];
            newCompleted.splice(newCompleted.indexOf(step), newCompleted.length - newCompleted.indexOf(step))
            setCompleted(new Set(newCompleted));
        }

        setActiveStep(step)
    }

    const handleStepIsDisabled = (step) => {
        if (!isNewApp)
            return formSteps[step].enabledInNewApp && step > activeStep
        return step > activeStep
    }

    const handleStepComplete = (step) => (!isNewApp && formSteps[step].enabledInNewApp) || (!!completed.size && completed.has(step))

    const handleSubmit = async (values, bag) => {
        console.log(isNewApp)
        if (isNewApp) {
            axios.post(baseUrl + '/InsertAppAction', serialize(values, {indices: true}))
                .then(response => {
                    dispatch(clearApp());
                    history.replace("/App");
                })
                .catch(e => {
                    toast.error(e.response.data)
                });
        } else
            axios.put(baseUrl + '/UpdateAppAction', serialize(values, {indices: true}))
                .then(response => {
                    dispatch(clearApp());
                })
                .catch(console.log);
    }

    useEffect(() => {
        if (!isNewApp && !Object.keys(formData).length) {
            history.replace("/App");
        }
    }, [formData])

    return <div className="popup-content">
        <Formik initialValues={formData} onSubmit={handleSubmit}
        >{
            props => (
                <>
                    <div style={{width: "100%"}}>
                        <Form style={{display: "flex", flexFlow: "column", height: "100%"}}>
                            <div style={{flex: "1 1 auto"}}>
                                <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                                    {formSteps.map(({label}, index) => <Step key={index}>
                                        <StepButton
                                            onClick={() => handleStep(index)}
                                            completed={handleStepComplete(index)}
                                            disabled={handleStepIsDisabled(index)}
                                        >{label}</StepButton>
                                    </Step>)}
                                </Stepper>
                                <div>
                                    {formSteps[activeStep].content({
                                        formik: props,
                                        nextStep: () => handleStep(activeStep + 1),
                                    })}
                                </div>
                            </div>
                        </Form>
                    </div>
                </>
            )

        }
        </Formik>


    </div>
}