import {createSlice} from "@reduxjs/toolkit";
import {setAuthHeader} from "../../utils/axiosHeaders";

export const authSliceName = 'auth';

export const authSlice = createSlice({
    name: authSliceName,
    initialState: {
        user: null,
        isLoadingUser: false
    },
    reducers:{
        setUser: (state,{payload}) => {
            setAuthHeader(payload.access_token);
            state.isLoadingUser = false;
            state.user = payload;
        },
        loadUser: state => {
            state.isLoadingUser = true;
        },
        clearUser: state => {
            state.user = null;
            state.isLoadingUser = false;
        }
    }
});

export const {setUser,loadUser,clearUser} = authSlice.actions;
export default authSlice.reducer;