import React from 'react';
import useBreadCrumbs from 'use-react-router-breadcrumbs';
import {NavLink, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

const PageHeader = ({pageTitle, clearContent}) => {
    const breadCrumbs = useBreadCrumbs();
    const history = useHistory();
    const dispatch = useDispatch();
    const handleBack = () => {
        dispatch(clearContent())
        history.goBack()
    }
    return (
        <div className="page-header">
            <div className="page-header-content header-elements-md-inline">
                <div className="page-title d-flex">
                    <h4><i className="icon-arrow-left52 mr-2" style={{fontSize: "2rem"}} onClick={handleBack}/>{pageTitle}</h4>
                </div>

                <div className="header-elements d-none py-0 mb-3 mb-md-0">
                    <div className="breadcrumb">
                        {breadCrumbs.map(({match, breadcrumb}) => (
                            <span key={match.url} className="breadcrumb-item">
                                <NavLink exact to={match.url} className="breadcrumb-item"
                                         activeClassName="active">{breadcrumb}</NavLink>
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PageHeader;