import React, {useState} from 'react';
import CardWrapper from "./CardWrapper";
import {Field} from "formik";
import FileUploader from 'devextreme-react/file-uploader';
import Thumb from '../Card Content/imageThumbnail'

export default ({index, onRemove}) => {
    return <CardWrapper title="Image"  onRemove={onRemove}>
        <Field
            id={`data[${index}].imgUri`}
            name={`data[${index}].imgUri`}
        >
            {({field, form, meta: {touched, error}}) => {
                return <div style={{display: "flex"}}>
                    <FileUploader selectButtonText="Select photo" labelText="" accept="image/*" uploadMode="useForm"
                                  onValueChange={(file) => {
                                      form.setFieldValue(field.name, file[0])
                                  }}/>
                    <Thumb file={field.value}/>
                </div>
            }}
        </Field>
    </CardWrapper>
}

