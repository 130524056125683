import React from "react";
import CardEditForm from "../components/CardList/cardEditForm";
import PageContent from "../components/PageContent";
import {clearCard} from "../store/slices/cardListSlice";

export default (props) => {
    const isNewRow = props?.location?.state?.isNewRow;
    const title = isNewRow ? "Create New Card" : "Edit Card";

    return <PageContent pageTitle={title} clearContent={clearCard}>
        <div className="container">
            <CardEditForm isNewCard={isNewRow}/>
        </div>
    </PageContent>
}
