import React from 'react';
import {Chip} from "@material-ui/core";

export const states = [
    "InProcess",
    "Published",
    "Archived"]

const stateColor=[
    {backgroundColor: 'orange', color: 'whitesmoke'},{backgroundColor: 'green', color: 'whitesmoke'},{backgroundColor: "tomato", color: 'whitesmoke'}]

function State({value}) {
    return (
        <Chip label={states[value]} size={"small"} style={{...stateColor[value]}}/>
    );
}

export default State;