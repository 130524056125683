import {cardListSliceName, setCard, setRefreshGridView} from "../../slices/cardListSlice";
import {API_SUCCESS, apiRequest, apiSuccess} from "../../actions/api.actions";
import {archiveCard, getCardDetail, inprocessCard, publishCard} from "../../actions/card.actions";

export const cardListMiddleware = ({dispatch}) => next => action => {
    next(action);

    switch (action.type) {
        case getCardDetail.type:
            dispatch(apiRequest(null, "GET", `/card?id=${action.payload}`, cardListSliceName, 'getCard'));
            break;
        case `${cardListSliceName}/${API_SUCCESS}`:
            switch (action.meta) {
                case 'getCard':
                    dispatch(setCard(action.payload.data));
                    break;
                case 'publishCard':
                case 'archiveCard':
                    dispatch(setRefreshGridView(true));
                    break;
            }
            break;
        case publishCard.type:
            dispatch(apiRequest({id: action.payload}, "POST", '/publishCard', cardListSliceName, 'publishCard'))
            break;
        case archiveCard.type:
            dispatch(apiRequest({id: action.payload}, "POST", '/archiveCard', cardListSliceName, 'archiveCard'))
            break;
        case inprocessCard.type:
            dispatch(apiRequest({id: action.payload}, "POST", '/inprocessCard', cardListSliceName, 'archiveCard'))
            break;
    }
}