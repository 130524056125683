import React from 'react';
import {Button, ButtonGroup, makeStyles} from "@material-ui/core";
import _ from 'lodash';

export const cardTypes = [null,
    "SingleReview",
    "MultipleReview",
    "VideoReview",
    "SmallNews",
    "TallNews",
    "GrandeNews",
    "VentiNews",
    "SmallAppBundle",
    "MediumAppBundle",
    "AppList",
    "Survey",
    "Video",
    "Game"]
export const cardTypesBySection = {
    app: [
        "SingleReview",
        "MultipleReview",
        // "VideoReview",
        "SmallAppBundle",
        "MediumAppBundle",
        "AppList",
        // "Survey",
        // "Video",
        // "Game",
    ],
    news: [
        "SmallNews",
        "TallNews",
        "GrandeNews",
        "VentiNews"],
};
const sections = [null, 'app', null, null, 'news'];


export default ({formik, nextStep}) => {

    const handleTypeClick = type => {
        formik.setFieldValue("cardType", cardTypes.indexOf(type));
        nextStep();
    }

    return <div style={{display: "flex", justifyContent: "space-evenly"}}>
        <ButtonGroup
            orientation="vertical"
            variant="outlined"
        >
            {cardTypesBySection[sections[formik.values.section]].filter(Boolean).map(type => <Button key={type}
                                                                                                     onClick={() => handleTypeClick(type)}>{_.startCase(type)}</Button>)}
        </ButtonGroup>
    </div>
}
