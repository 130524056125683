import {Button, TextField} from "@material-ui/core";
import React from "react";
import DoneAllIcon from '@material-ui/icons/DoneAll';

export default ({formik}) => {

    const handleSubmitAppInfoClick = () => {
        formik.handleSubmit();
    }

    const AppImages = (value) => {
        if (value !== "" && value !== undefined) {
            return (
                <div style={{
                    padding: '2px',
                    margin: '2px',
                    border: '1px solid #ccc',
                    borderRadius: '3px',
                    boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
                }}>
                    <img src={value} height="200px" width="100%" style={{objectFit: "contain"}}
                         alt={formik.values.name}/>
                </div>
            );
        }
    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "50%",
            margin: "auto"
        }}>
            <div style={{display: "flex", justifyContent: "space-evenly", marginBottom: '9px'}}>
                {AppImages(formik.values.imageUrl)}
                {AppImages(formik.values.altImageUrl)}
            </div>
            <TextField
                id="name"
                name="name" label="App Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                InputProps={{
                    readOnly: false,
                }}
                sx={{my: 3}}
            />
            <TextField
                id="owner"
                name="owner" label="App Owner Company"
                value={formik.values.owner}
                onChange={formik.handleChange}
                InputProps={{
                    readOnly: false,
                }}
                sx={{my: 3}}
            />
            <TextField
                id="value"
                name="value" label="App Value"
                value={formik.values.value}
                onChange={formik.handleChange}
                InputProps={{
                    readOnly: false,
                }}
                sx={{my: 3}}
            />
            <TextField
                id="currencyName"
                name="currencyName" label="Currency Name"
                value={formik.values.currencyName}
                onChange={formik.handleChange}
                InputProps={{
                    readOnly: true,
                }}
                sx={{my: 3}}
            />
            <TextField
                id="store"
                name="store" label="Store Name"
                value={formik.values.store}
                onChange={formik.handleChange}
                InputProps={{
                    readOnly: true,
                }}
                sx={{my: 3}}
            />
            <TextField
                id="description"
                name="description" label="App Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                multiline
                rows={4}
                sx={{my: 3}}
            />
            <Button disableRipple
                    size="large"
                    variant="outlined"
                    onClick={() => handleSubmitAppInfoClick()}
            >
                <DoneAllIcon fontSize={"large"}/>
                Submit App Info
            </Button>
        </div>
    );

}