import {createStore} from "devextreme-aspnet-data-nojquery";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef} from "react";
import State, {states} from "../components/AppList/State";
import PageContent from "../components/PageContent";
import {Button, Column, DataGrid, HeaderFilter, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {getAppDetail, publishApp, unpublishApp} from "../store/actions/app.actions";
import {setRefreshGridView} from "../store/slices/cardListSlice";

const baseUrl = `${window.config.ApiBase}/api/v1/CMS`;

const remoteDataSource = createStore({
    key: 'id',
    loadUrl: baseUrl + '/GetAppsList',
});
export default ({title}) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const dataGridRef = useRef();
    const formData = useSelector(({appList}) => appList.data);
    const refreshGridView = useSelector(({cardList}) => cardList.refreshGridView);

    useEffect(() => {
        if (formData && Object.keys(formData).length)
            history.push("/App/Edit")
    }, [formData])

    useEffect(() => {
        if (refreshGridView) {
            dataGridRef.current.instance.refresh();
        }
        dispatch(setRefreshGridView(false))
    })

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: refreshDataSource,
            },
        }, {
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'add',
                onClick: handleAddApp,
            },
        })
    }

    const stateHeaderFilter = states.filter(t => t).map((text, value) => ({
            text, value,
        }
    ))

    const handleAddApp = () => {
        history.push("/App/Edit", {isNewRow: true})
    }
    const handleOnRowEdit = ({row: {key}}) => {
        dispatch(getAppDetail(key));
    }
    const handlePublish = ({row: {key}}) => {
        dispatch(publishApp(key))
    }

    const refreshDataSource = () => {
        dataGridRef.current.instance.refresh();
    }

    return <PageContent pageTitle={title}>
        <DataGrid dataSource={remoteDataSource} remoteOperations={true}
                  onToolbarPreparing={onToolbarPreparing}
                  ref={dataGridRef} columnAutoWidth={true}>
            <SearchPanel visible/>
            <HeaderFilter visible/>
            <Paging defaultPageSize={10}/>
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[1, 10, 50, 100]}
                showInfo={true}/>
            <Column dataField="altImageUrl" cellRender={(data) => {
                return <img
                    src={data.value && !data.value.toLowerCase().startsWith("http") ? "https://gbizzservices.com" + data.value : data.value}
                    alt="No Image" className="img-thumbnail" width="100"/>
            }} allowSorting={false} alignment="center"/>
            <Column dataField="name" dataType="string"/>
            <Column dataField="appStatus" cellRender={(data) => <State value={data.value}/>} alignment="left">
                <HeaderFilter dataSource={stateHeaderFilter}/>
            </Column>
            <Column type="buttons" width={110}>
                <Button hint="Edit" icon="edit" onClick={handleOnRowEdit}/>
                <Button hint="Publish" icon="upload" visible={({row}) => row.data.appStatus === 0}
                        onClick={handlePublish}/>
                <Button hint="UnPublish" icon="download" visible={({row}) => row.data.appStatus === 1}
                        onClick={handlePublish}/>
            </Column>
        </DataGrid>
    </PageContent>

}