import React, {useEffect, useState} from 'react';
import PageContent from "../components/PageContent";
import {Formik, Field, Form} from 'formik';
import {Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField} from "@material-ui/core";
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import axios from "axios";
import {serialize} from "object-to-formdata";
import {useHistory} from "react-router-dom";
import {Upload, Select, Tabs} from 'antd';
import {LoadingOutlined, PlusOutlined, InboxOutlined} from '@ant-design/icons';

import 'antd/dist/antd.css';
import {Editor} from "@tinymce/tinymce-react";
import ImgCrop from "antd-img-crop";

const baseUrl = `${window.config.ApiBase}/api/v1/CMS`;

const {TabPane} = Tabs;
const {Dragger} = Upload;
const {Option} = Select;

const Notification = props => {
    const history = useHistory();

    const [cards, setCards] = useState([]);

    useEffect(() => {
        axios.get(`${baseUrl}/getCards`).then(({data}) => {
            setCards(data);
        });
    }, [])

    const handleSubmit = (values) => {
        axios.post(baseUrl + '/send-data', serialize(values, {indices: true}), {headers: {"Content-Type": "multipart/form-data"}})
            .then(response => {
                history.replace("/");
            })
            .catch(console.log)
    }

    const handleDataMessageSubmit = (values) => {
        axios.post(baseUrl + '/send', serialize(values, {indices: true}), {headers: {"Content-Type": "multipart/form-data"}})
            .then(response => {
                history.replace("/");
            })
            .catch(console.log)

    }

    const [localValue, setLocalValue] = useState();


    return (
        <PageContent pageTitle={"Push Notifications"}>
            <Tabs type="card" defaultActiveKey="2" centered>
                <TabPane tab="Premium Notification" key="1">
                    <div className="container">
                        <Formik initialValues={{
                            title: '',
                            content: '',
                            action: 'follow_link',
                            description: '',
                            url: '',
                            scheduledToSend: null,
                            visibility: 0
                        }}
                                onSubmit={handleSubmit}>
                            <Form>
                                <div className="mb-2">
                                    <Field id="visibility" name="visibility">
                                        {({field, form}) => {
                                            return <FormControl component="fieldset" variant="standard">
                                                <FormLabel component="legend">Visibility</FormLabel>
                                                <FormGroup row>
                                                    <FormControlLabel control={<Checkbox checked={field.value === 1}
                                                                                         onChange={() => form.setFieldValue(field.name, field.value !== 1 ? 1 : 0)}
                                                                                         name="Tüm Bildirimler"/>}
                                                                      label="Tüm Bildirimler"/>
                                                    <FormControlLabel control={<Checkbox checked={field.value === 2}
                                                                                         onChange={() => form.setFieldValue(field.name, field.value !== 2 ? 2 : 0)}
                                                                                         name="Özel Teklifler"/>}
                                                                      label="Özel Teklifler"/>
                                                </FormGroup>
                                            </FormControl>
                                        }}
                                    </Field>
                                </div>
                                <div className="mb-2">
                                    <Field id="title" name="title">
                                        {({field, form, meta: {touched, error}}) => {
                                            return <TextField
                                                fullWidth
                                                id="title"
                                                name="title"
                                                label="Title"
                                                value={field.value}
                                                onChange={form.handleChange}
                                                error={form.touched.title && Boolean(form.errors.title)}
                                                helperText={form.touched.title && form.errors.title}
                                            />
                                        }}
                                    </Field>
                                </div>
                                <div className="mb-2">
                                    <Field id="description" name="description">
                                        {({field, form, meta: {touched, error}}) => {
                                            return <Editor apiKey="wagqbgjh92bjdkler5dwhdnim0hhn5st8r3gjakh7buhw59p"
                                                           value={field.value}
                                                           onEditorChange={(e) => {
                                                               form.handleChange({target: {name: field.name, value: e}})
                                                           }}
                                                           init={{
                                                               height: 350,
                                                               menubar: false,
                                                               plugins: [
                                                                   'advlist autolink lists link anchor',
                                                                   'searchreplace visualblocks code fullscreen',
                                                                   'paste wordcount emoticons'
                                                               ],
                                                               toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
                                                                   'removeformat | link autolink | emoticons | code fullscreen',
                                                               content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                               forced_root_block: '',
                                                               branding: false
                                                           }}
                                            />
                                        }}

                                    </Field>
                                </div>
                                <div className="mb-2">
                                    <Field id="url" name="url">
                                        {({field, form, meta: {touched, error}}) => {
                                            return <TextField
                                                fullWidth
                                                id="url"
                                                name="url"
                                                label="URL"
                                                value={field.value}
                                                onChange={form.handleChange}
                                                error={form.touched.url && Boolean(form.errors.url)}
                                                helperText={form.touched.url && form.errors.url}
                                            />
                                        }}
                                    </Field>
                                </div>
                                <div className="my-3">
                                    <div className="row">
                                        <div className="col">
                                            <Field id="imageUrl" name="imageUrl">
                                                {({field, form, meta: {touched, error}}) => {
                                                    const handleFileUpload = (e) => {
                                                        const {file} = e;
                                                        form.setFieldValue(field.name, file)
                                                    }
                                                    return <FormControl component="fieldset" variant="standard"
                                                                        className="w-100">
                                                        <FormLabel component="legend">Image</FormLabel>
                                                        {/*<ImgCrop>*/}
                                                        <Upload name={field.name} onChange={handleFileUpload}
                                                                listType='picture-card'
                                                                beforeUpload={() => false} multiple={false}>
                                                            <PlusOutlined/>
                                                        </Upload>
                                                        {/*</ImgCrop>*/}
                                                    </FormControl>
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col">
                                            <Field id="iconUrl" name="iconUrl">
                                                {({field, form, meta: {touched, error}}) => {
                                                    const handleFileUpload = (e) => {
                                                        const {file} = e;
                                                        form.setFieldValue(field.name, file)
                                                    }
                                                    return <FormControl component="fieldset" variant="standard"
                                                                        className="w-100">
                                                        <FormLabel component="legend">Icon</FormLabel>
                                                        {/*<ImgCrop>*/}
                                                        <Upload name={field.name} onChange={handleFileUpload}
                                                                listType='picture-card'
                                                                beforeUpload={() => false} multiple={false}>
                                                            <PlusOutlined/>
                                                        </Upload>
                                                        {/*</ImgCrop>*/}
                                                    </FormControl>
                                                }}
                                            </Field>
                                        </div>
                                    </div>

                                </div>
                                <div className="mb-2">
                                    <Field id="action" name="action">
                                        {({field, form, meta: {touched, error}}) => {
                                            return <TextField disabled={true}
                                                              fullWidth
                                                              id="action"
                                                              name="action"
                                                              label="Action"
                                                              value={field.value}
                                                              onChange={form.handleChange}
                                                              error={form.touched.action && Boolean(form.errors.action)}
                                                              helperText={form.touched.action && form.errors.action}
                                            />
                                        }}
                                    </Field>
                                </div>
                                <div className="mb-2">
                                    <Field id="scheduledToSend" name="scheduledToSend">
                                        {({field, form, meta: {touched, error}}) => {
                                            return <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDateTimePicker
                                                    label="Schedule PN"
                                                    value={field.value}
                                                    onChange={(e, x) => {
                                                        console.log(moment(e._d).format())
                                                        form.setFieldValue(field.name, moment(e._d).format())
                                                    }}
                                                    animateYearScrolling
                                                    disablePast
                                                    showTodayButton
                                                    ampm={false}
                                                />
                                            </MuiPickersUtilsProvider>
                                        }}
                                    </Field>
                                </div>
                                <div className="ml-auto mt-5">
                                    <Button variant="outlined" color="primary" type="submit"> Send To ALl </Button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </TabPane>
                <TabPane tab="Data Notification" key="2">
                    <div className="container">
                        <Formik initialValues={{
                            title: '',
                            content: '',
                            action: 'open_card',
                            description: '',
                            cardId: 0,
                            scheduledToSend: null,
                            visibility: 0
                        }} onSubmit={handleDataMessageSubmit}>
                            <Form>
                                <div className="mb-2">
                                    <Field id="visibility" name="visibility">
                                        {({field, form}) => {
                                            return <FormControl component="fieldset" variant="standard">
                                                <FormLabel component="legend">Visibility</FormLabel>
                                                <FormGroup row>
                                                    <FormControlLabel control={<Checkbox checked={field.value === 1}
                                                                                         onChange={() => form.setFieldValue(field.name, field.value !== 1 ? 1 : 0)}
                                                                                         name="Tüm Bildirimler"/>}
                                                                      label="Tüm Bildirimler"/>
                                                    <FormControlLabel control={<Checkbox checked={field.value === 2}
                                                                                         onChange={() => form.setFieldValue(field.name, field.value !== 2 ? 2 : 0)}
                                                                                         name="Özel Teklifler"/>}
                                                                      label="Özel Teklifler"/>
                                                </FormGroup>
                                            </FormControl>
                                        }}
                                    </Field>
                                </div>
                                <div className="mb-2">
                                    <Field id="title" name="title">
                                        {({field, form, meta: {touched, error}}) => {
                                            return <TextField
                                                fullWidth
                                                id="title"
                                                name="title"
                                                label="Title"
                                                value={field.value}
                                                onChange={form.handleChange}
                                                error={form.touched.title && Boolean(form.errors.title)}
                                                helperText={form.touched.title && form.errors.title}
                                            />
                                        }}
                                    </Field>
                                </div>
                                <div className="mb-2">
                                    <Field id="description" name="description">
                                        {({field, form, meta: {touched, error}}) => {
                                            return <Editor apiKey="wagqbgjh92bjdkler5dwhdnim0hhn5st8r3gjakh7buhw59p"
                                                           value={field.value}
                                                           onEditorChange={(e) => {
                                                               form.handleChange({target: {name: field.name, value: e}})
                                                           }}
                                                           init={{
                                                               height: 350,
                                                               menubar: false,
                                                               plugins: [
                                                                   'advlist autolink lists link anchor',
                                                                   'searchreplace visualblocks code fullscreen',
                                                                   'paste wordcount emoticons'
                                                               ],
                                                               toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
                                                                   'removeformat | link autolink | emoticons | code fullscreen',
                                                               content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                               forced_root_block: '',
                                                               branding: false
                                                           }}
                                            />
                                        }}

                                    </Field>
                                </div>
                                <div className="mb-2">
                                    <Field id="cardId" name="cardId">
                                        {({field, form, meta: {touched, error}}) => {
                                            return <Select
                                                showSearch
                                                style={{width: "100%"}}
                                                placeholder="Select a Card"
                                                optionFilterProp="children"
                                                onChange={value => {
                                                    form.setFieldValue(field.name, value)
                                                }}
                                                filterOption={(input, option) =>
                                                    input.length >= 3 && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                options={cards}
                                            />
                                        }}
                                    </Field>
                                </div>
                                <div className="my-3">
                                    <div className="row">
                                        <div className="col">
                                            <Field id="imageUrl" name="imageUrl">
                                                {({field, form, meta: {touched, error}}) => {
                                                    const handleFileUpload = (e) => {
                                                        const {file} = e;
                                                        form.setFieldValue(field.name, file)
                                                    }
                                                    return <FormControl component="fieldset" variant="standard"
                                                                        className="w-100">
                                                        <FormLabel component="legend">Image</FormLabel>
                                                        {/*<ImgCrop>*/}
                                                        <Upload name={field.name} onChange={handleFileUpload}
                                                                listType='picture-card'
                                                                beforeUpload={() => false} multiple={false}>
                                                            <PlusOutlined/>
                                                        </Upload>
                                                        {/*</ImgCrop>*/}
                                                    </FormControl>
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col">
                                            <Field id="iconUrl" name="iconUrl">
                                                {({field, form, meta: {touched, error}}) => {
                                                    const handleFileUpload = (e) => {
                                                        const {file} = e;
                                                        form.setFieldValue(field.name, file)
                                                    }
                                                    const handlePreview = async file=> {
                                                        // if (!file.url && !file.preview) {
                                                        //     file.preview = await getBase64(file.originFileObj);
                                                        // }
                                                    }
                                                    return <FormControl component="fieldset" variant="standard"
                                                                        className="w-100">
                                                        <FormLabel component="legend">Icon</FormLabel>
                                                        {/*<ImgCrop>*/}
                                                        <Upload name={field.name} onChange={handleFileUpload}
                                                                listType='picture-card' onPreview={handlePreview}
                                                                beforeUpload={() => false} multiple={false}>
                                                            <PlusOutlined/>
                                                        </Upload>
                                                        {/*</ImgCrop>*/}
                                                    </FormControl>
                                                }}
                                            </Field>
                                        </div>
                                    </div>

                                </div>
                                <div className="mb-2">
                                    <Field id="action" name="action">
                                        {({field, form, meta: {touched, error}}) => {
                                            return <TextField disabled={true}
                                                              fullWidth
                                                              id="action"
                                                              name="action"
                                                              label="Action"
                                                              value={field.value}
                                                              onChange={form.handleChange}
                                                              error={form.touched.action && Boolean(form.errors.action)}
                                                              helperText={form.touched.action && form.errors.action}
                                            />
                                        }}
                                    </Field>
                                </div>
                                <div className="mb-2">
                                    <Field id="scheduledToSend" name="scheduledToSend">
                                        {({field, form, meta: {touched, error}}) => {
                                            return <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDateTimePicker
                                                    label="Schedule PN"
                                                    value={field.value}
                                                    onChange={(e, x) => {
                                                        console.log(moment(e._d).format())
                                                        form.setFieldValue(field.name, moment(e._d).format())
                                                    }}
                                                    animateYearScrolling
                                                    disablePast
                                                    showTodayButton
                                                    ampm={false}
                                                />
                                            </MuiPickersUtilsProvider>
                                        }}
                                    </Field>
                                </div>
                                <div className="ml-auto mt-5">
                                    <Button variant="outlined" color="primary" type="submit"> Send To ALl </Button>
                                </div>
                            </Form>
                        </Formik>

                    </div>
                </TabPane>
            </Tabs>

        </PageContent>
    )
};

export default Notification;