import React from 'react';
import {CardContent, CardHeader, IconButton} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

export default ({title, children, onRemove}) => {
    return (
        <>
            <CardHeader title={title} action={<IconButton onClick={onRemove}>
                <DeleteIcon/>
            </IconButton>}/>
            <CardContent>
                {children}
            </CardContent>
        </>
    );
};

