import React from 'react';
import PageHeader from "./pageHeader";

function PageContent({pageTitle, children, clearContent}) {
    return <>
        <PageHeader pageTitle={pageTitle} clearContent={clearContent}/>
        <div className="page-content pt-0">
            <div className="content-wrapper">
                {children}
            </div>
        </div>
    </>
}

export default PageContent;