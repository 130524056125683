import React, {useEffect, useState} from 'react';

export default ({file, width}) => {
    const [loading, setLoading] = useState(false);
    const [thumb, setThumb] = useState(file);


    useEffect(() => {
        if (file && !(file instanceof File)) {

            setThumb(!file.toLowerCase().startsWith("http") ? "https://gbizzservices.com" + file : file)
        } else
            setLoading(!!file);
    }, [file])


    useEffect(() => {
        if (!loading) return;

        const reader = new FileReader();
        reader.onloadend = () => {
            setLoading(false);
            setThumb(reader.result);
        };
        reader.readAsDataURL(file);
    }, [loading])

    return (file || thumb ? loading ? <p>Loading ...</p> :
        <img src={thumb} alt={(file ? file.name : thumb) || file
        } className="img-thumbnail m-2" width={width ? width : 300} height={width ? "auto" : 300}/> : null)

}

