    import React, {useState} from 'react';
import CardWrapper from "./CardWrapper";
import {Field} from "formik";
import {TextField} from "@material-ui/core";

export default ({index, onRemove}) => {
    return <CardWrapper title="YouTube" onRemove={onRemove}>
        <Field
            id={`data[${index}].videoUri`}
            name={`data[${index}].videoUri`}
        >
            {({field, meta: {touched, error}}) => {
                const [localValue, setLocalValue] = useState(field.value);
                return <TextField {...field} value={localValue} onChange={({target: {value}}) => setLocalValue(value)}
                                  onBlur={field.onChange} label="URL" fullWidth
                                  error={touched && Boolean(error)}/>
            }}
        </Field>
    </CardWrapper>
}

