import React, {useEffect, useState} from 'react';

import {FieldArray} from "formik";

import {Button, Card, CircularProgress, makeStyles} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import ImageIcon from '@material-ui/icons/Image';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Popup} from "devextreme-react";

import TextComponent from "../Card Content/TextComponent";
import HighlightedTextComponent from "../Card Content/HighlightTextComponent";
import ImageComponent from "../Card Content/ImageComponent";
import VideoComponent from "../Card Content/VideoComponent";
import YouTubeComponent from "../Card Content/YouTubeComponent";
import AppComponent from "../Card Content/appComponent";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import {useDispatch, useSelector} from "react-redux";
import {setIsSubmitting} from "../../store/slices/cardListSlice";

const componentsByMk = {
    text: (index, onRemove) => <TextComponent index={index} key={`mk-${index}`} onRemove={onRemove}/>,
    hText: (index, onRemove) => <HighlightedTextComponent index={index} key={`mk-${index}`} onRemove={onRemove}/>,
    img: (index, onRemove) => <ImageComponent index={index} key={`mk-${index}`} onRemove={onRemove}/>,
    video: (index, onRemove) => <VideoComponent index={index} key={`mk-${index}`} onRemove={onRemove}/>,
    youtube: (index, onRemove) => <YouTubeComponent index={index} key={`mk-${index}`} onRemove={onRemove}/>,
    app: (index, onRemove) => <AppComponent index={index} key={`mk-${index}`} onRemove={onRemove}/>,
}

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        margin: theme.spacing(2),
    },
    button: {width: "10rem", height: "10rem"},
    buttonLabel: {flexDirection: "column"},
    buttonIcon: {marginBottom: theme.spacing(1)},
}));

export default ({formik}) => {
    const classes = useStyles();
    const isSubmitting = useSelector(x => x.cardList.isSubmitting);
    const [addComponentPopupVisible, setAddComponentPopupVisible] = useState(isSubmitting);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const handleSubmitCardClick = () => {
        formik.handleSubmit();
        setSubmitDisabled(true);
    }

    useEffect(() => {
        console.log(isSubmitting, submitDisabled, !isSubmitting && submitDisabled)
        if (!isSubmitting && submitDisabled) {
            setSubmitDisabled(false);
        }
    }, [isSubmitting])

    const [dragStarted, setDragStarted] = useState(false)


    return <FieldArray name={"data"}>
        {({move, push, remove}) => {
            const renderContent = () => (<div className="container-fluid">
                <div className="row align-items-center my-2">
                    <div className="col d-flex justify-content-center">
                        <Button classes={{root: classes.button, label: classes.buttonLabel}} disableRipple
                                size="large"
                                variant="outlined"
                                onClick={() => {
                                    handleAddComponent("text")
                                }}>
                            <FontDownloadIcon className={classes.buttonIcon} fontSize={"large"}/>
                            Text
                        </Button>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <Button classes={{root: classes.button, label: classes.buttonLabel}} disableRipple
                                size="large"
                                variant="outlined"
                                onClick={() => {
                                    handleAddComponent("hText")
                                }}>
                            <FontDownloadIcon className={classes.buttonIcon} fontSize={"large"}/>
                            Highlighted Text
                        </Button>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <Button classes={{root: classes.button, label: classes.buttonLabel}} disableRipple
                                size="large"
                                variant="outlined" onClick={() => {
                            handleAddComponent("img")
                        }}>
                            <ImageIcon className={classes.buttonIcon} fontSize={"large"}/>
                            Image
                        </Button>
                    </div>
                </div>
                <div className="row align-items-center my-2">
                    <div className="col d-flex justify-content-center">
                        <Button classes={{root: classes.button, label: classes.buttonLabel}} disableRipple
                                size="large"
                                variant="outlined" onClick={() => {
                            handleAddComponent("video")
                        }}>
                            <OndemandVideoIcon className={classes.buttonIcon} fontSize={"large"}/>
                            Video
                        </Button>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <Button classes={{root: classes.button, label: classes.buttonLabel}} disableRipple
                                size="large"
                                variant="outlined" onClick={() => {
                            handleAddComponent("youtube")
                        }}>
                            <YouTubeIcon className={classes.buttonIcon} fontSize={"large"}/>
                            Youtube
                        </Button>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <Button classes={{root: classes.button, label: classes.buttonLabel}} disableRipple
                                size="large"
                                variant="outlined" onClick={() => {
                            handleAddComponent("app")
                        }}>
                            <PhoneIphoneIcon className={classes.buttonIcon} fontSize={"large"}/>
                            Application
                        </Button>
                    </div>
                </div>
            </div>)

            const handleDragStart = () => {
                setDragStarted(true)
            }

            const handleComponentDrag = result => {
                setDragStarted(false)
                if (!result.destination) return;

                if (result.destination.index === result.source.index) return;

                move(result.source.index, result.destination.index);
            }
            const handleAddComponent = type => {
                push({mk: type, title: "", content: ""});
                setAddComponentPopupVisible(false)
            }

            const handleComponentRemove = index => {
                remove(index);
            }

            return (<div id="cartContentContainer" style={{display: "flex", flexFlow: "column", height: "100%"}}>
                <div style={{display: "flex", justifyContent: "center", marginBottom: "1rem"}}>
                    <Button size={"small"} variant={"outlined"} startIcon={<AddIcon/>} onClick={() => {
                        setAddComponentPopupVisible(true)
                    }}>Add Component</Button>
                </div>
                <DragDropContext style={{flex: "0 1 auto"}} onDragEnd={handleComponentDrag}
                                 onDragStart={handleDragStart}>
                    <Droppable droppableId="componentList">{(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {formik.values.data && formik.values.data.map(({mk}, index) => (
                                <Draggable draggableId={`${mk}-${index}`} index={index} key={`${mk}-${index}`}>
                                    {provided => (<Card ref={provided.innerRef} className={classes.cardRoot} raised
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                        {componentsByMk[mk](index, () => handleComponentRemove(index))}
                                    </Card>)}
                                </Draggable>
                            ))}
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>
                {
                    formik.values.data && formik.values.data.length > 0 && !dragStarted &&
                    <div style={{display: "flex", justifyContent: "center", marginBottom: "1rem"}}>
                        <Button size={"small"} variant={"outlined"} startIcon={<AddIcon/>} onClick={() => {
                            setAddComponentPopupVisible(true)
                        }}>Add Component</Button>
                    </div>
                }
                <Button disableRipple
                        size="large"
                        variant="outlined"
                        fullWidth
                        onClick={handleSubmitCardClick}
                        disabled={submitDisabled}
                >
                    {!submitDisabled ? <><DoneAllIcon fontSize={"large"}/> Submit</> : <CircularProgress/>}
                </Button>
                <Popup visible={addComponentPopupVisible} onHiding={() => {
                    setAddComponentPopupVisible(false)
                }} showCloseButton={true} showTitle={true} title="Add a new Component"
                       contentRender={renderContent} container="#cartContentContainer">
                </Popup>
            </div>)
        }}
    </FieldArray>

} 

