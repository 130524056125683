import {API_REQUEST, apiError, apiRequest, apiSuccess} from "../../actions/api.actions";
import axios from "axios";

export const apiMiddleWare = ({dispatch}) => next => action => {
    next(action);

    if (action.type.includes(API_REQUEST)) {
        const {data, meta: {method, url, sliceName, meta}} = action.payload;
        const baseURL = `${window.config.ApiBase}/api/v1/CMS`;
        axios({baseURL, method, url, data})
            .then(({data}) => {
                dispatch(apiSuccess(data, sliceName, meta))
            })
            .catch(err => dispatch(apiError(err.message, sliceName)));
    }
}