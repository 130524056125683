import React, {useEffect, useState} from 'react';
import {Button, FormControl, FormLabel, makeStyles, TextField} from "@material-ui/core";
import AppsMultiSelect from '../AppMulltiSelect'
import {Field, FieldArray} from 'formik';

import {createStore} from "devextreme-aspnet-data-nojquery";
import {ColorBox, FileUploader} from "devextreme-react";
import Thumb from "./imageThumbnail";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import {setIsSubmitting} from "../../store/slices/cardListSlice";
import {useDispatch, useSelector} from "react-redux";
import {ValidationSchema} from "../CardList/cardGeneralInformation";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            flex: "1 1 auto",
        },
        width: "100%",
    },
    formControl: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            flex: "1 1 auto",
        },
        marginTop: theme.spacing(2),
        width: "100%",
    },
}));


const serviceUrl = `${window.config.ApiBase}/api/v1/CMS`;
const appStore = createStore({
    key: "id",
    loadUrl: serviceUrl + "/GetApps",
});

const AppBundle = ({formik}) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [options, setOptions] = useState([]);

    const isSubmitting = useSelector(state => state.cardList.isSubmitting)
    const dispatch = useDispatch();

    const handleSubmitCardClick = () => {
        const keys = Object.keys(ValidationSchema.fields).reduce((result, current) => (result[current] = true, result), {});
        formik.setTouched({...formik.touched, ...keys})
        formik.validateForm().then(result => {
            console.log(result)
            if (!Object.keys(result).length) formik.handleSubmit();
        });
    }
    useEffect(() => {
        console.log(formik.values.cardType)
        dispatch(setIsSubmitting(formik.isSubmitting))
    }, [formik])

    useEffect(() => {
        const options = appStore.load().then((data) => {
            setOptions(data.map(x => ({
                value: x.id,
                label: x.name
            })));
            setIsLoading(false);
        })
    }, [])


    return <FormControl component="fieldset" className={classes.root} style={{width: "100%"}}>
        <FormLabel component="legend">App Bundle (S-M App Bundle, App List)</FormLabel>
        <div className="dx-field">
            <Field
                id={`title`}
                name={`title`}
            >
                {({field, form, meta: {touched, error}}) => {
                    console.log(touched,error);
                    return <TextField {...field} value={field.value} onChange={form.handleChange}
                                      label="Title" fullWidth
                                      error={touched && Boolean(error)}
                                      helperText={touched && Boolean(error)}/>
                }}
            </Field>
            <Field name="apps" component={AppsMultiSelect} options={options} isLoading={isLoading}/>
            {formik.touched.apps && Boolean(formik.errors.apps) && <div style={{color: "tomato"}}>at Least 2 Apps Must Be selected</div>}
            {
                formik.values.cardType === 10 && <>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Cover Image</FormLabel>
                        <div style={{display: "flex"}}>
                            <FileUploader selectButtonText="Select photo" labelText="" accept="image/*"
                                          uploadMode="useForm"
                                          onValueChange={(file) => {
                                              formik.setFieldValue("coverImg", file[0])
                                          }}/>
                            <Thumb file={formik.values.coverImg}/>
                        </div>
                    </FormControl>
                    <div className="dx-field">
                        <div className="dx-field-label">Background Color</div>
                        <div className="dx-field-value">
                            <ColorBox
                                value={formik.values.backgroundColor}
                                applyValueMode="instantly"
                                onValueChanged={(e) => formik.setFieldValue("backgroundColor", e.value)}
                            />
                        </div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Text Color</div>
                        <div className="dx-field-value">
                            <ColorBox
                                value={formik.values.textColor}
                                applyValueMode="instantly"
                                onValueChanged={(e) => formik.setFieldValue("textColor", e.value)}
                            />
                        </div>
                    </div>
                </>
            }
            <div className="mt-2">
                <Button disableRipple
                        size="large"
                        variant="outlined"
                        fullWidth
                        onClick={handleSubmitCardClick}
                        disabled={isSubmitting}
                >
                    <DoneAllIcon fontSize={"large"}/>
                    Submit
                </Button>
            </div>
        </div>

    </FormControl>
};


export default AppBundle;   