import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import axios from "axios";
import {serialize} from 'object-to-formdata';

import {useDispatch, useSelector} from "react-redux";

import '../../styles/popup.css'
import {Button, Step, StepButton, Stepper} from "@material-ui/core";
import CardGeneralInformation from "./cardGeneralInformation";
import CardContent from "./CardContent";
import {clearCard, setIsSubmitting} from "../../store/slices/cardListSlice";
import {useHistory} from "react-router-dom";
import toast from "react-hot-toast";


import DeviceEmulator from 'react-device-emulator';
import 'react-device-emulator/lib/styles/style.css';
import parse from 'html-react-parser';
import Thumb from '../Card Content/imageThumbnail'
import VThumb from '../Card Content/videoThumbnail'
import CardSection from "./CardSection";
import CardTypeComponent from "./cardTypeComponent";
import {ValidationSchema} from "./cardGeneralInformation";

const formSteps = [
    {
        label: "Select Section",
        enabledInNewCard: true,
        content: ({formik, nextStep}) => <CardSection formik={formik} nextStep={nextStep}/>,
    },
    {
        label: "Select Card Type",
        enabledInNewCard: true,
        content: ({formik, nextStep}) => <CardTypeComponent formik={formik} nextStep={nextStep}/>,
    },
    {
        label: "About",
        enabledInNewCard: false,
        content: ({formik, nextStep}) => <CardGeneralInformation formik={formik} nextStep={nextStep}/>,
        ValidationSchema,
    },
    {label: "Content", enabledInNewCard: false, content: ({formik}) => <CardContent formik={formik}/>},
];

const baseUrl = `${window.config.ApiBase}/api/v1/CMS`;

export default ({isNewCard = false}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const formData = useSelector(({cardList}) => cardList.data);
    const initialStep = formSteps.findIndex(x => x.enabledInNewCard === isNewCard);
    const [activeStep, setActiveStep] = useState(initialStep);
    const [completed, setCompleted] = useState(new Set());

    const handleStep = (step) => {
        if (step > activeStep) {
            const newCompleted = new Set(completed);
            newCompleted.add(activeStep);
            setCompleted(newCompleted);
        } else {
            const newCompleted = [...completed];
            newCompleted.splice(newCompleted.indexOf(step), newCompleted.length - newCompleted.indexOf(step))
            setCompleted(new Set(newCompleted));
        }

        setActiveStep(step)
    }

    const handleStepIsDisabled = (step) => {
        if (!isNewCard)
            return formSteps[step].enabledInNewCard && step > activeStep
        return step > activeStep
    }

    const handleStepComplete = (step) => (!isNewCard && formSteps[step].enabledInNewCard) || (!!completed.size && completed.has(step))

    const handleSubmit = async (values, bag) => {
        dispatch(setIsSubmitting(true));

        console.log(values);
        values.appType = parseInt(values.appType) || null;
        values.grandeNewsType = parseInt(values.grandeNewsType) || null;
        if (isNewCard) {
            axios.post(baseUrl + '/InsertAction', serialize(values, {indices: true}), {headers: {"Content-Type": "multipart/form-data"}})
                .then(response => {
                    toast.success('Card Submitted Successfully');
                    dispatch(clearCard());
                    history.replace("/Card");
                })
                .catch(() => {
                    dispatch(setIsSubmitting(false));
                    toast.error('Unable to Submit Card');
                });
        } else
            axios.put(baseUrl + '/UpdateAction', serialize(values, {indices: true}), {headers: {"Content-Type": "multipart/form-data"}})
                .then(response => {
                    toast.success('Card Submitted Successfully')
                    dispatch(clearCard());
                    history.replace("/Card");
                })
                .catch(e => {
                    dispatch(setIsSubmitting(false));
                    toast.error('Unable to Submit Card');
                });

    }

    useEffect(() => {
        if (!isNewCard && !Object.keys(formData).length) {
            history.replace("/Card");
        }
    }, [formData])

    return <div className="popup-content">
        <Formik initialValues={formData} onSubmit={handleSubmit} validationSchema={ValidationSchema}>{
            props => (
                <>
                    <div style={{width: "60%"}}>
                        <Form style={{display: "flex", flexFlow: "column", height: "100%"}}>
                            <div style={{flex: "1 1 auto"}}>
                                <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                                    {formSteps.map(({label}, index) => (index !== 3 || [8, 9, 10].indexOf(props.values.cardType) === -1) &&
                                        <Step key={index}>
                                            <StepButton
                                                onClick={() => handleStep(index)}
                                                completed={handleStepComplete(index)}
                                                disabled={handleStepIsDisabled(index)}
                                            >{label}</StepButton>
                                        </Step>)}
                                </Stepper>
                                <div>
                                    {formSteps[activeStep].content({
                                        formik: props,
                                        nextStep: () => handleStep(activeStep + 1),
                                    })}
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div style={{width: "40%", display: "none"}}>
                        <div style={{height: "100vh", position: "fixed"}}>
                            <div style={{display: "flex"}}>
                                <DeviceEmulator type="mobile" withoutChrome
                                                style={{justifyContent: "center", alignItems: "center"}}>
                                    <>{props.values.data && props.values.data.map(({mk, ...rest}, i) => {
                                        if (mk === "text") {
                                            return <div
                                                key={`${mk}-${i}`}>{parse((rest.title ?? "") + (rest.content ?? ""))}</div>
                                        } else if (mk === "img") {
                                            return <Thumb file={rest.imgUri} width={"100%"} key={`${mk}-${i}`}/>
                                        } else if (mk === "video") {
                                            return <VThumb file={rest.videoUri} width={"100%"} key={`${mk}-${i}`}/>
                                        }
                                    })}</>
                                </DeviceEmulator>
                            </div>
                        </div>
                    </div>
                </>
            )

        }
        </Formik>


    </div>

}


