import {Button, TextField} from "@material-ui/core";
import React from "react";
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";


const serviceUrl = `${window.config.ApiBase}/api/v1/CMS`;

export default ({formik, nextStep}) => {

    const handleGetAppInfoClick = () => {
        let regex = new RegExp('[?&]' + "id" + '(=([^&#]*)|&|#|$)'), results = regex.exec(formik.values.appurl);
        if (results && results[2]) {
            toast.promise(
                axios.get(serviceUrl + `/GetAppDetails?appurl=${decodeURIComponent(results[2].replace(/\+/g, ' '))}`), {
                    loading: 'Contacting Google...',
                    success: 'App Details Fetched. Continue...',
                    error: 'Failed To Contact Google. Try Again Later'
                })
                .then(response => {
                    formik.values.name = response.data.name;
                    formik.values.owner = response.data.ownerCompany;
                    formik.values.value = response.data.value;
                    formik.values.currencyName = response.data.currencyName;
                    formik.values.store = response.data.store;
                    formik.values.description = response.data.description;
                    formik.values.imageUrl = response.data.imageUrl;
                    formik.values.altImageUrl = response.data.altImageUrl;
                    nextStep();
                })
                .catch(console.log);
        }
    }

    return (<div style={{
        display: "flex", flexDirection: "column", justifyContent: "space-evenly", width: "50%", margin: "auto"
    }}>
        <TextField
            id="appurl"
            name="appurl" label="App Share Url"
            value={formik.values.appurl}
            onChange={formik.handleChange}
            helperText="Enter App Share Url"
            sx={{my: 3}}
        />
        <Button disableRipple
                size="large"
                variant="outlined"
                onClick={() => handleGetAppInfoClick()}
        >
            <PermDeviceInformationIcon fontSize={"large"}/>
            Get App Info
        </Button>
    </div>);

}
export const ValidationSchema = Yup.object().shape({
    appurl: Yup.string().required().url()
})