import React from "react";
import PageContent from "../components/PageContent";
import AppEditForm from "../components/AppList/appEditForm";
import {clearApp} from "../store/slices/appListSlice";

export default (props) => {
    const isNewRow = props?.location?.state?.isNewRow;
    const title = isNewRow ? "Add New App" : "Edit App";

    return <PageContent pageTitle={title} clearContent={clearApp}>
        <div className="container">
            <AppEditForm isNewApp={isNewRow}/>
        </div>
    </PageContent>
}
