import {createAction} from "@reduxjs/toolkit";

export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';

export const apiRequest = (body, method, url, sliceName, meta) => ({
    type: `${sliceName}/${API_REQUEST}`,
    payload: {
        data: body, meta: {method, url, sliceName, meta},
    },
});

export const apiSuccess = (response, sliceName, meta) => ({
    type: `${sliceName}/${API_SUCCESS}`,
    payload: {
        data: response,
    },
    meta,
});

export const apiError = (error, sliceName) => ({
    type: `${sliceName}/${API_ERROR}`,
    payload: {
        data: error, meta: {sliceName},
    },
});