import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel, InputLabel,
    makeStyles, MenuItem,
    Radio,
    RadioGroup, Select,
    TextField
} from "@material-ui/core";
import {ColorBox, SelectBox, FileUploader} from "devextreme-react";
import * as Yup from 'yup';
import Thumb from "../Card Content/imageThumbnail";
import AppBundle from "../Card Content/appBundle";
import {Field} from "formik";
import DataSource from "devextreme/data/data_source";
import {createStore} from "devextreme-aspnet-data-nojquery";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {TreeSelect} from "antd";

import 'antd/dist/antd.css'
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            flex: "1 1 auto",
        },
        width: "100%",
    },
    formControl: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            flex: "1 1 auto",
        },
        marginTop: theme.spacing(2),
        width: "100%",
    },
}));

const baseUrl = `${window.config.ApiBase}/api/v1/CMS`;

const remoteDataSource = new DataSource({
    store: createStore({
        key: 'id',
        loadUrl: baseUrl + '/GetApps',
    }),
    paginate: true, pageSize: 10,
})

const grandNewsTypes = [null, "Bizzmag Seri", "Bizzmag Özel Gün", "Bizzmag Evergreen", "Bizzmag Haber", "Bizzmag Yorum", "Marka İşbirliği"]

const CardGeneralInformation = ({formik, nextStep}) => {
    const classes = useStyles();

    const [categories, setCategories] = useState([]);

    const handleLoadSubCategory = ({id}) => new Promise(resolve => {
        axios.get(`${baseUrl}/GetCategories/${id}`).then(response => {
            setCategories([...categories, ...response.data])
            resolve();
        })
    })


    useEffect(() => {
        axios.get(`${baseUrl}/GetCategories`).then(response => {
            setCategories(response.data.map(parentNode => ({
                ...parentNode,
                checkable: parentNode.isLeaf,
                selectable: parentNode.isLeaf
            })))
        })
    }, [])


    const handleNextStep = () => {
        const keys = Object.keys(ValidationSchema.fields).reduce((result, current) => (result[current] = true, result), {});
        formik.setTouched({...formik.touched, ...keys})
        formik.validateForm().then(result => {
            console.log(result)
            if (!Object.keys(result).length) nextStep();
        });
    }

    return ([8, 9, 10].indexOf(formik.values.cardType) > -1 ? <AppBundle formik={formik}/> : <>
            <FormControl component="fieldset" className={classes.root} style={{width: "100%"}}>
                <FormLabel component="legend">General Information</FormLabel>
                <div style={{display: "flex"}}>
                    <TextField
                        id="ptitle"
                        name="ptitle" label="Title Prefix"
                        value={formik.values.ptitle}
                        onChange={formik.handleChange}
                        error={formik.touched.ptitle && Boolean(formik.errors.ptitle)}
                        helperText={formik.touched.ptitle && formik.errors.ptitle}
                    />
                    <TextField
                        id="title"
                        name="title" label="Title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />
                </div>
                <div style={{display: "flex"}}>
                    <TextField style={{width: "50%"}}
                               id="topic"
                               name="topic"
                               label="Topic"
                               onBlur={formik.handleBlur}

                               value={formik.values.topic}
                               onChange={formik.handleChange}
                               error={formik.touched.topic && Boolean(formik.errors.topic)}
                               helperText={formik.touched.topic && formik.errors.topic}
                    />
                    <ColorBox
                        value={formik.values.backgroundColor}
                        applyValueMode="instantly"
                        onValueChanged={(e) => formik.setFieldValue("backgroundColor", e.value)}
                    />
                </div>
                <div style={{display: "flex"}}>
                    <TextField multiline maxRows={4}
                               id="shortDescription"
                               name="shortDescription"
                               label="Short Description"
                               onBlur={formik.handleBlur}
                               value={formik.values.shortDescription}
                               onChange={formik.handleChange}
                               error={formik.touched.shortDescription && Boolean(formik.errors.shortDescription)}
                               helperText={formik.touched.shortDescription && formik.errors.shortDescription}
                    />
                </div>
                {formik.values.cardType !== 2 && [4, 5, 6, 7, 8, 9, 10].indexOf(formik.values.cardType) === -1 &&
                    <div style={{marginTop: "1rem", marginBottom: "1rem"}}>
                        <FormLabel>App</FormLabel>
                        <div style={{display: "flex"}}>
                            <Field
                                id={`appId`}
                                name={`appId`}>
                                {({field, form, meta: {touched, error}}) => {
                                    return <SelectBox dataSource={remoteDataSource}
                                                      style={{width: "60%", marginRight: '1rem'}}
                                                      displayExpr="name"
                                                      valueExpr="id"
                                                      value={field.value}
                                                      onValueChanged={e => {
                                                          form.setFieldValue(field.name, e.value)
                                                      }}
                                                      searchEnabled={true}
                                                      searchExpr={['name']}
                                                      minSearchLength={3}
                                                      searchTimeout={300}
                                                      showDataBeforeSearch
                                                      isValid={!(touched && Boolean(error))}
                                    />
                                }}
                            </Field>
                            <Field id='appType' name='appType'>
                                {({field, form, meta: {touched, error}}) => {
                                    return <RadioGroup value={`${field.value}`}
                                                       onClick={({target: {value}}) => {
                                                           console.log(value, field.value)
                                                           form.setFieldValue(field.name, field.value === value ? null : value)
                                                       }}
                                                       row>
                                        <FormControlLabel control={<Radio/>} label='Uygulama' value="1"/>
                                        <FormControlLabel control={<Radio/>} label='Oyun' value="2"/>
                                    </RadioGroup>
                                }}
                            </Field>
                        </div>
                    </div>}
                <div style={{display: "flex"}}>
                    <FormLabel className="mr-2">Card Categories</FormLabel>
                    <Field id="subCategories" name="subCategories">
                        {({field, form}) => {

                            const handleChange = value => {
                                form.setFieldValue(field.name, value.map(subCategoryId => ({subCategoryId})));
                            }


                            return <TreeSelect treeDefaultExpandAll
                                               showSearch allowClear
                                               value={field.value?.map(x => x.subCategoryId)}
                                               placeholder={"Select Categories"}
                                               onChange={handleChange}
                                               treeData={categories}
                                               style={{
                                                   border: formik.touched.subCategories && formik.errors.subCategories ? "1px solid red" : "",
                                                   width: '100%'
                                               }}
                                               treeDataSimpleMode
                                               treeCheckable

                            />
                        }}
                    </Field>
                </div>
                {formik.values.cardType === 6 &&
                    <div style={{marginTop: "1rem", marginBottom: "1rem"}}>
                        <FormLabel>Type</FormLabel>
                        <div style={{display: "flex"}}>
                            <Field id='grandeNewsType' name='grandeNewsType'>
                                {({field, form, meta: {touched, error}}) =>
                                    <Select label='Type' value={field.value ?? ""} variant="outlined"
                                            style={{m: 1, width: "100%"}}
                                            onChange={({target: {value}}) => {
                                                form.setFieldValue(field.name, value)
                                            }}>
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {grandNewsTypes.map((t, i) => t &&
                                            <MenuItem value={i} key={t + i}>{t}</MenuItem>)}
                                    </Select>}
                            </Field>
                        </div>
                    </div>
                }
            </FormControl>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Cover Image</FormLabel>
                <div style={{
                    display: "flex",
                    border: formik.touched.coverImg && formik.errors.coverImg ? "1px solid red" : "",
                    margin: "2rem 0"
                }}>
                    <FileUploader
                        selectButtonText="Select photo"
                        accept="image/*"
                        uploadMode="useForm"
                        onValueChange={(file) => {
                            formik.setFieldValue("coverImg", file[0])
                        }}/>
                    <Thumb file={formik.values.coverImg}/>
                </div>
            </FormControl>
            <Button disableRipple
                    fullWidth
                    size="large"
                    variant="outlined"
                    onClick={() => handleNextStep()}
            >
                <NavigateNextIcon fontSize={"large"}/>
                Next
            </Button>
        </>
    )
}

export const ValidationSchema = Yup.object().shape({
    cardType: Yup.number().required(),
    title: Yup.string().required(),
    coverImg: Yup.mixed()
        .when("cardType", {
            is: (cardType) => [8, 9, 10].indexOf(cardType) === -1,
            then: Yup.mixed().required()
        }),
    subCategories: Yup.array()
        .when("cardType", {
            is: (cardType) => [8, 9, 10].indexOf(cardType) === -1,
            then: Yup.array().required("Choose at least 1 Category")
        }),
    appId: Yup.number()
        .when("cardType", {
            is: (cardType) => [2, 4, 5, 6, 7, 8, 9, 10].indexOf(cardType) === -1,
            then: Yup.number().required()
        }),
    apps: Yup.array()
        .when("cardType",
            {
                is: (cardType) => [8, 9, 10].indexOf(cardType) > -1,
                then: Yup.array().min(2).required()
            }
        )
})

export default CardGeneralInformation;