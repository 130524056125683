import React, {useEffect, useState} from 'react';
import CardWrapper from "./CardWrapper";
import {Field} from "formik";
import FileUploader from 'devextreme-react/file-uploader';
import VideoThumb from '../Card Content/videoThumbnail'
import ImageThumb from '../Card Content/imageThumbnail'
import {TextField} from "@material-ui/core";

export default ({index, onRemove}) => {
    return <CardWrapper title="Video"  onRemove={onRemove}>
        <Field
            id={`data[${index}].videoUri`}
            name={`data[${index}].videoUri`}
        >
            {({field, form, meta: {touched, error}}) => {
                const [duration, setDuration] = useState();

                useEffect(() => {
                    if (duration && duration > 0)
                        form.setFieldValue(`data[${index}].duration`, Math.round(duration * 1000))
                }, [duration])

                return <div style={{display: "flex"}}>
                    <FileUploader selectButtonText="Select video" labelText="" accept="video/*" uploadMode="useForm"
                                  onValueChange={(file) => {
                                      form.setFieldValue(field.name, file[0])
                                  }}/>
                    <VideoThumb file={field.value} setDuration={setDuration}/>
                </div>
            }}
        </Field>
        <Field
            id={`data[${index}].coverUri`}
            name={`data[${index}].coverUri`}
        >
            {({field, form, meta: {touched, error}}) => {
                return <div style={{display: "flex"}}>
                    <FileUploader selectButtonText="Select cover photo" labelText="" accept="image/*"
                                  uploadMode="useForm"
                                  onValueChange={(file) => {
                                      form.setFieldValue(field.name, file[0])
                                  }}/>
                    <ImageThumb file={field.value}/>
                </div>
            }}
        </Field>
        <Field
            id={`data[${index}].adsCategory`}
            name={`data[${index}].adsCategory`}
        >
            {({field, form, meta: {touched, error}}) => {
                const [localValue, setLocalValue] = useState(field.value);
                return <TextField {...field} value={localValue} onChange={({target: {value}}) => setLocalValue(value)}
                                  onBlur={field.onChange} label="Ads Category" fullWidth
                                  error={touched && Boolean(error)}/>
            }}
        </Field>
    </CardWrapper>
}

