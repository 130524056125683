import {useHistory} from "react-router-dom";
import React, {useEffect} from "react";
import {signinRedirectCallback} from "../services/userService";

function SigninOidc() {
    const history = useHistory()
    useEffect(() => {
        console.log("Hi from Istanbul")

        async function signinAsync() {
            signinRedirectCallback().then(() => {
                console.log("THEN");
                history.push('/')
            }).catch(err => {
                console.log({err})
            })
        }

        signinAsync()
    }, [history])

    return (
        <div>
            Redirecting ...
        </div>
    )
}

export default SigninOidc