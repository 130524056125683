import {createSlice, createAction} from "@reduxjs/toolkit";

export const appListSliceName = 'appList';

export const appListSlice = createSlice({
    name: appListSliceName,
    initialState: {
        data: {},
        isLoading: false,
        isPopUpVisible: false,
    },
    reducers: {
        setApp: (state, {payload}) => {
            state.data = payload;
        },
        clearApp: state => {
            state.data = {};
        },
    },
});

export const fetchAppList = createAction(`${appListSliceName}/fetchAppList`)

export const {setApp, clearApp} = appListSlice.actions;
export default appListSlice.reducer;