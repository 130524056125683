import React from 'react';
import CardWrapper from "./CardWrapper";
import {Field} from 'formik';
import {Editor} from "@tinymce/tinymce-react";


export default ({index, onRemove}) => {
    return <CardWrapper title="Highlighted Text" onRemove={onRemove}>
        <Field
            id={`data[${index}].content`}
            name={`data[${index}].content`}
        >
            {({field, form, meta: {touched, error}}) => {
                return <Editor apiKey="wagqbgjh92bjdkler5dwhdnim0hhn5st8r3gjakh7buhw59p"
                               value={field.value}
                               onEditorChange={(e) => {
                                   form.handleChange({target: {name: field.name, value: e}})
                               }}
                               init={{
                                   height: 350,
                                   menubar: false,
                                   plugins: [
                                       'advlist autolink lists link anchor',
                                       'searchreplace visualblocks code fullscreen',
                                       'paste wordcount emoticons'
                                   ],
                                   toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
                                       'removeformat | link autolink | emoticons | code fullscreen',
                                   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                   forced_root_block: '',
                                   branding: false
                               }}
                />
            }}
        </Field>
    </CardWrapper>
}

