import {Provider} from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,

} from "react-router-dom";
import store from "./store/store";
import Layout from "./components/layout";
import CardList from "./pages/CardList";
import CardEdit from "./pages/CardEdit";
import Notification from "./pages/Notification";
import {AuthProvider} from "oidc-react";
import AppList from "./pages/AppList";
import AppEdit from "./pages/AppEdit";

import userManager, {loadUserFromStorage} from "./services/userService";
import {useEffect, useLayoutEffect} from "react";
import SignoutOidc from "./pages/signout-oidc";
import SigninOidc from "./pages/signin-oidc";
import toast, {Toaster} from "react-hot-toast";


function App() {
    useEffect(() => {
        // fetch current user from cookies
        loadUserFromStorage(store)  
    }, [])

    return (
        <Provider store={store}>
            <AuthProvider userManager={userManager} store={store}>
                <Toaster position="top-right" containerStyle={{top: "6.5rem"}}/>
                <Router>
                    <Layout>
                        <Switch>
                            <Route exact path={["/", "/Card"]}
                                   render={(props) => (<CardList {...props} title={"Widget List"}/>)}/>
                            <Route exact path="/Card/Edit" component={CardEdit}/>
                            <Route exact path="/App" render={(props) => (<AppList {...props} title={"App List"}/>)}/>
                            <Route exact path="/App/Edit" component={AppEdit}/>
                            <Route exact path="/PushNotification" component={Notification}/>
                            <Route path="/signout-oidc" component={SignoutOidc}/>
                            <Route path="/signin-oidc" component={SigninOidc}/>
                        </Switch>
                    </Layout>
                </Router>
            </AuthProvider>
        </Provider>
    );
}


export default App;
