import {
    LOADING_USER, 
    STORE_USER, 
    STORE_USER_ERROR, 
    USER_EXPIRED, 
    USER_SIGNED_OUT} from "./types";
import {createAction} from "@reduxjs/toolkit";

export const storeUser = createAction(STORE_USER);
export const loadingUser = createAction(LOADING_USER);
export const storeUserError = createAction(STORE_USER_ERROR);
export const userExpired = createAction(USER_EXPIRED);
export const userSignedOut = createAction(USER_SIGNED_OUT);
