import {loadingUser, storeUser, storeUserError, userExpired, userSignedOut} from "../../actions/auth.actions";
import {setAuthHeader} from "../../../utils/axiosHeaders";
import {clearUser, loadUser, setUser} from "../../slices/authSlice";

export const authMiddleware = ({dispatch}) => next => action => {
    next(action);
    
    switch (action.type){
        case storeUser.type:
            dispatch(setUser(action.payload));
            break;
        case loadingUser.type:
            dispatch(loadUser);
            break;
        case userExpired.type:
        case storeUserError.type:
        case userSignedOut.type:
            dispatch(clearUser);
            break;
    }
}