import React from 'react';
import {Button, makeStyles} from "@material-ui/core";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PublicIcon from '@material-ui/icons/Public';

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        margin: theme.spacing(2),
    },
    button: {width: "10rem", height: "10rem"},
    buttonLabel: {flexDirection: "column"},
    buttonIcon: {marginBottom: theme.spacing(1)},
}));

const sections = [null, 'app', null, null, 'news'];

export default ({formik, nextStep}) => {
    const classes = useStyles();

    const handleSectionClick = section => {
        formik.setFieldValue("section", sections.indexOf(section));
        nextStep();
    }

    return <div style={{display: "flex", justifyContent: "space-evenly"}}>
        <Button classes={{root: classes.button, label: classes.buttonLabel}} disableRipple
                size="large"
                variant="outlined"
                onClick={() => handleSectionClick("app")}
        >
            <PhoneIphoneIcon className={classes.buttonIcon} fontSize={"large"}/>
            Application
        </Button>
        <Button classes={{root: classes.button, label: classes.buttonLabel}} disableRipple
                size="large"
                variant="outlined"
                onClick={() => handleSectionClick("news")}
        >
            <PublicIcon className={classes.buttonIcon} fontSize={"large"}/>
            BizzMag
        </Button>
    </div>
} 