import {getAppDetail, publishApp, unpublishApp} from "../../actions/app.actions";
import {API_SUCCESS, apiRequest} from "../../actions/api.actions";
import {appListSliceName, setApp} from "../../slices/appListSlice";
import {setRefreshGridView} from "../../slices/cardListSlice";

export const appListMiddleware = ({dispatch}) => next => action => {
    next(action);
    
    switch (action.type) {
        case getAppDetail.type:
            dispatch(apiRequest(null, "GET", `/app?id=${action.payload}`, appListSliceName, 'getApp'));
            break;
        case `${appListSliceName}/${API_SUCCESS}`:
            switch (action.meta) {
                case 'getApp':
                    dispatch(setApp(action.payload.data));
                    break;
                case 'publishApp':
                case 'archiveApp':
                    dispatch(setRefreshGridView(true));
                    break;
            }
            break;
        case publishApp.type:
            dispatch(apiRequest({id: action.payload}, "POST", '/publishApp', appListSliceName, 'publishApp'))
            break;
    }
}