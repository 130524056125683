import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    Button,
    Column,
    DataGrid,
    HeaderFilter,
    Pager,
    Paging, SearchPanel,
    Export,
} from "devextreme-react/data-grid";
import {createStore} from "devextreme-aspnet-data-nojquery";
import {Dropdown, Button as AntButton, Menu, Modal, Progress} from "antd";
import {Workbook} from 'exceljs';
import {saveAs} from "file-saver";
import CardType, {cardTypes} from "../components/CardList/CardType";
import State, {states} from "../components/CardList/State";
import {useDispatch, useSelector} from "react-redux";
import {archiveCard, getCardDetail, inprocessCard, publishCard} from "../store/actions/card.actions";
import PageContent from "../components/PageContent";
import {setRefreshGridView} from "../store/slices/cardListSlice";
import {exportDataGrid} from "devextreme/excel_exporter";
import {Popup} from "devextreme-react";
import DateBox from 'devextreme-react/date-box';
import {ToolbarItem} from "devextreme-react/popup";
import axios from "axios";
import toast from "react-hot-toast";
import dayjs from "dayjs";

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './assets/datagrid.css'

const baseUrl = `${window.config.ApiBase}/api/v1/CMS`;

const remoteDataSource = createStore({
    key: 'id',
    loadUrl: baseUrl + '/GetAction',

});


export default ({title}) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const dataGridRef = useRef();
    const formData = useSelector(({cardList}) => cardList.data);
    const refreshGridView = useSelector(({cardList}) => cardList.refreshGridView);
    const [showPublishPopUp, setShowPublishPopUp] = useState(false)
    const timeToSet = new Date();
    timeToSet.setMilliseconds(0);
    timeToSet.setSeconds(0);
    timeToSet.setMinutes(Math.round(timeToSet.getMinutes() / 15) * 15)

    const [publishSchedule, setPublishSchedule] = useState(timeToSet)
    const [scheduleButtonDisabled, setScheduleButtonDisabled] = useState(true)
    const [cardId, SetCardId] = useState();
    const [filtered, setFiltered] = useState(false);

    useEffect(() => {
        if (formData && Object.keys(formData).length)
            history.push("/Card/Edit")
    }, [formData])

    useEffect(() => {
        if (refreshGridView) {
            dataGridRef.current.instance.refresh();
        }
        dispatch(setRefreshGridView(false))
    })

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: refreshDataSource,
            },
        }, {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'clock',
                onClick: filterScheduled,
                elementAttr: {id: "filterScheduled"}
            },
        }, {
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'add',
                onClick: handleAddCard,
            },
        })
    }

    const cardTypeHeaderFilter = cardTypes.filter(t => t).map((text, value) => ({
            text, value: value + 1,
        }
    ))

    const stateHeaderFilter = states.filter(t => t).map((text, value) => ({
            text, value,
        }
    ))

    const handleAddCard = () => {
        history.push("/Card/Edit", {isNewRow: true})
    }
    const handleOnRowEdit = ({row: {key}}) => {
        dispatch(getCardDetail(key));
    }
    const handlePublish = ({key}) => {
        console.log(key)
        if (window.confirm("Do You want to Publish This Card?"))
            dispatch(publishCard(key));
    }
    const handleArchive = ({key}) => {
        if (window.confirm("Do You want to Archive This Card?"))
            dispatch(archiveCard(key));
    }
    const handleInProcess = ({key}) => {
        if (window.confirm("Change to InProcess state This Card?"))
            dispatch(inprocessCard(key));
    }

    const refreshDataSource = () => {
        dataGridRef.current.instance.refresh();
    }

    const filterScheduled = e => {
        const dataGrid = dataGridRef.current.instance;
        if (!filtered)
            dataGrid.filter(['publishedOn', '>', new Date()]);
        else dataGrid.clearFilter();

        setFiltered(prevState => !prevState);

        const element = document.getElementById("filterScheduled");
        if (!filtered) element.classList.add('filtered');
        else element.classList.remove('filtered')
    }

    const handleMenuClick = row => ({key}) => {

        switch (parseInt(key)) {
            case 1:
                handlePublish(row);
                break;
            case 2:
                handleArchive(row);
                break;
            case 3:
                handleInProcess(row);
                break;
        }
    }

    const menu = (row) => {

        return <Menu onClick={handleMenuClick(row)}>
            <Menu.Item key="1" disabled={row.data.state === 1}>Publish</Menu.Item>
            <Menu.Item key="2" disabled={row.data.state === 2}>Archive</Menu.Item>
            <Menu.Item key="3" disabled={row.data.state === 0}>In Process</Menu.Item>
        </Menu>

    }

    const handleExport = e => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        e.component.beginUpdate();
        e.component.columnOption(6, "visible", false);
        e.component.columnOption("cardAppType", "visible", true);
        e.component.columnOption("grandNewsType", "visible", true);
        e.component.columnOption("topic", "visible", true);
        e.component.columnOption("appName", "visible", true);
        e.component.columnOption("category", "visible", true);
        

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            customizeCell: ({gridCell, excelCell}) => {
                if (gridCell.rowType === 'data') {
                    if (gridCell.column.dataField === 'cardType') {
                        excelCell.value = cardTypeHeaderFilter[gridCell.value - 1].text;
                    }
                    if (gridCell.column.dataField === 'state') {
                        excelCell.value = gridCell.value === 0 ? 'In Process' : gridCell.value === 1 ? 'Published' : 'Archived'
                    }
                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], {type: 'application/octet-stream'});
                const rightNow = new Date();
                saveAs(blob, `Cards-${rightNow.toISOString().slice(0, 10)}.xlsx`);
            });
        }).then(() => {
            e.component.columnOption(6, "visible", true);
            e.component.columnOption("cardAppType", "visible", false);
            e.component.columnOption("grandNewsType", "visible", false);
            e.component.columnOption("topic", "visible", false);
            e.component.columnOption("appName", "visible", false);
            e.component.columnOption("category", "visible", false);
            e.component.endUpdate();
        });
        e.cancel = true;
    }
    const handleSchedulePublish = e => {
        setShowPublishPopUp(prevState => !prevState);
        SetCardId(e.row.data.id);
    }

    const renderPublishPopUpContent = () => {
        return <DateBox value={publishSchedule} type="datetime"
                        disabledDates={args => new Date(args.date.toDateString()) < new Date(new Date().toDateString())}
                        onValueChanged={e => {
                            setPublishSchedule(e.value);
                            setScheduleButtonDisabled(false);
                        }}/>
    }

    const SchedulePublish = () => {
        if (cardId)
            axios.post(baseUrl + "/SchedulePublish", {
                id: cardId,
                publishSchedule
            })
                .then(() => toast.success(`Card Successfully Scheduled to Publish on ${publishSchedule.toString()}`))
                .catch(() => toast.error('Card Failed to Schedule for Publish!'))
    }

    const renderPublishedOnCell = ({data: {publishedOn}}) => {
        if (publishedOn) {
            const date = dayjs(publishedOn);
            const dateString = date.format("YYYY-MM-DD HH:mm");

            return <div style={{color: date.isBefore(dayjs(), 'm') ? "inherit" : "tomato"}}>{dateString}</div>
        }
    }

    return <PageContent pageTitle={title}>
        <DataGrid dataSource={remoteDataSource} remoteOperations={true}
                  onToolbarPreparing={onToolbarPreparing}
                  ref={dataGridRef} columnAutoWidth={true}
                  rowAlternationEnabled
                  onExporting={handleExport}
        >
            <SearchPanel visible/>
            <HeaderFilter visible/>
            <Paging defaultPageSize={10}/>
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[1, 10, 50, 100]}
                showInfo={true}/>
            <Column dataField="id" visible={window.config.isDebugMode}/>
            <Column dataField="coverImage" cellRender={(data) => {
                return <img
                    src={data.value && !data.value.toLowerCase().startsWith("http") ? "https://gbizzservices.com" + data.value : data.value}
                    alt="No Image"
                    className="img-thumbnail"
                    width="100"/>
            }} allowSorting={false} alignment="center"/>
            <Column dataField="cardType" cellRender={(data) => <CardType cardType={data.value}/>}
                    alignment="left">
                <HeaderFilter dataSource={cardTypeHeaderFilter}/>
            </Column>
            <Column dataField="title"/>
            <Column dataField="createdOn" format="yyyy-MM-dd HH:mm" dataType="date"/>
            <Column dataField="publishedOn" cellRender={renderPublishedOnCell}/>
            <Column 
                cssClass="no-border"
                alignment="center"
                cellRender={({data: {publishedOn}}) => dayjs(publishedOn).isAfter(dayjs(), 'm') ?
                    <i className="dx-icon-clock"/> : <span/>}
                width="30"
            />
            <Column dataField="cardAppType" visible={false} allowFiltering={false}/>
            <Column dataField="grandNewsType" visible={false} allowFiltering={false}/>
            <Column dataField="topic" visible={false} allowFiltering={false}/>
            <Column dataField="appName" visible={false} allowFiltering={false}/>
            <Column dataField="category" visible={false} allowFiltering={false}/>
            <Column dataField="state" cellRender={(data) => <State value={data.value}/>} alignment="center" width="100">
                <HeaderFilter dataSource={stateHeaderFilter}/>
            </Column>
            <Column cellRender={(data) => <Dropdown overlay={menu(data)}>
                <AntButton>
                    State
                </AntButton>
            </Dropdown>} alignment="center" width="100"/>
            <Column type="buttons">
                <Button hint="Edit" icon="edit" onClick={handleOnRowEdit}/>
                <Button hint="Schedule Publish" icon="clock"
                        onClick={handleSchedulePublish}/>
            </Column>

            <Export enabled allowExportSelectedData excelFilterEnabled/>
        </DataGrid>

        <Popup
            visible={showPublishPopUp}
            onHiding={() => setShowPublishPopUp(prevState => !prevState)}
            dragEnabled={true}
            closeOnOutsideClick={true}
            showCloseButton={true}
            contentRender={renderPublishPopUpContent}
            showTitle={true}
            title="Schedule Publish Date and Time"
            width="650"
            height="300">
            <ToolbarItem widget="dxButton" toolbar="bottom" location="after"
                         options={{
                             text: "Schedule", icon: "event",
                             // disabled: scheduleButtonDisabled,
                             onClick: SchedulePublish,
                         }}/>
        </Popup>

    </PageContent>

}