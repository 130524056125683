import React from "react";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import Footer from "./footer";
import PageContent from "./PageContent";

const Layout = ({children}) => (<>
            <Navbar/>
            {children}
            <Footer/>
        </>
    )
;

export default Layout;